import React from "react"
import { graphql, Link } from "gatsby"
import * as styles from "../styles/page/top.module.scss"

// 共通コンポーネント
import Layout from "../components/layouts/default"
import Meta from "../components/layouts/meta"

// 記事データ
import TopFeature from "../components/parts/top/feature" // 特集
import TopProject from "../components/parts/top/project" // プロジェクト
import TopInformation from "../components/parts/top/information" // お知らせ

// markup
const IndexPage = ({ data }) => {
  const featureData = data.feature.edges
  const staticArticle = data.staticArticle.edges
  const projectData = data.project.edges
  const informationData = data.information.edges

  const title = 'トップ'
  const description = '2020年10月、我が国は「2050年カーボンニュートラル」を宣言し、2050年までに、温室効果ガスの排出を全体としてゼロにする目標を掲げました。この目標は、従来の政府方針を大幅に前倒すものであり、並大抵の努力で実現できるものではありません。エネルギー・産業部門の構造転換や、大胆な投資によるイノベーションといった現行の取組を大幅に加速することが必要です。 このため、グリーンイノベーション基金事業（以下「基金事業」という。）により、NEDOに2兆円の基金を造成し、官民で野心的かつ具体的な目標を共有した上で、これに経営課題として取り組む企業等に対して、10年間、研究開発・実証から社会実装までを継続して支援します。'

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
      />
      <div className={styles.mainVisual}>
        <div className={styles.mainVisualInner}>
          <div className={styles.mainVisualText}>
            <h1 className={styles.mainVisualTextEyeCatch}>カーボンニュートラルな未来へ。</h1>
            <p className={styles.mainVisualTextLead}>カーボンニュートラルへの挑戦こそが、<br />日本に次の成長をもたらす原動力。</p>
            <p className={styles.mainVisualTextLead}>今こそ、技術大国・日本の出番です。<br />世界を変えるイノベーションを、次々と。</p>
            <p className={styles.mainVisualTextLead}>そして、ひとりひとりの力を合わせて、<br />カーボンニュートラルな未来へ。<br />2050年。そこには、新しい日本が待っています。</p>
          </div>
        </div>
      </div>

      <main className={styles.topContents}>
      
        <div className={styles.movie}>
          <div className={styles.contentsBanner}>
            <div className={styles.contentsBannerWrapList}>
              <a href="/article/life-support/" className={styles.contentsBannerCard}>
                <img src="/images/top/bnr-report-life-support.png" alt="" />
                <h2 className={styles.contentsBannerCardSmallTitle}>
                  開発現場見学レポート
                </h2>
              </a>
              <a href="https://www.youtube.com/watch?v=fXDPwbQrNiA&list=PLZH3AKTCrVsXEcdJJep6fl4Mb2Wy4eHL5" className={styles.contentsBannerCard} target="_blank" rel="noopener noreferrer">
                <img src="/images/top/interview-banner-vol3.png" alt="" />
                <div className={styles.contentsBannerCardMoveiButton}>
                  <img src="/images/top/movie-button.png" alt="" />
                </div>
                <h2 className={styles.contentsBannerCardSmallTitle}>
                経営者インタビュー ～バイオものづくり～
                </h2>
              </a>
            </div>
          </div>

          <p className={styles.allLists}><Link className={styles.allListsBullet} to="/movie/">MOVIEを全てを見る</Link></p>
        </div>

        <div className={`${styles.contentsBanner} mb-6`}>
          <div className={styles.contentsBannerWrap}>
            <a href="https://www.nedo.go.jp/content/100955752.pdf" className={styles.contentsBannerCard} target="_blank" rel="noopener noreferrer">
              <img src="/images/top/bnr-pamphlet.png" alt="" />
              <h2 className={styles.contentsBannerCardSmallTitle}>
                「グリーンイノベーション基金事業」パンフレット
              </h2>
            </a>
            <Link to="/about/public-contribution/" className={styles.contentsBannerCard}>
              <img src="/images/top/pic-projectDashboard.png" alt="" />
              <h2 className={styles.contentsBannerCardTitle}>
                公募等情報
              </h2>
              <span className={styles.contentsBannerCardBullet}>詳しく見る</span>
            </Link>
          </div>
        </div>

        <div className={styles.feature}>
          <h2 className={styles.headingTop}>特集記事</h2>
          <div className={`${styles.featureInner} mb-3`}>
            {featureData.map( (item, id) => {
              return (id < 3) ? <TopFeature key={`top-feature-${id}`} index={id} data={item.node.frontmatter} /> : ''
            })}
          </div>
          <div className={styles.featureInner}>
            {featureData.map( (item, id) => {
              const { title, mainImage, slug } = item.node.frontmatter
              const data = { title: title, mainImage: mainImage, slug: slug }
              return (id == 3) ? <TopFeature key={`top-feature-${id}`} index={id} data={data} /> : ''
            })}
            {staticArticle.map( (item, id) => {
              return <TopFeature key={`top-feature-${id}`} index={id} data={item.node.frontmatter} />
            })}
          </div>
          <p className={styles.allLists}><Link className={styles.allListsBullet} to="/article/">全てを見る</Link></p>
        </div>
        
        <div className={styles.project}>
          <h2 className={styles.headingTop}>プロジェクト</h2>
          <div className={styles.projectInner}>
            {projectData.map( (item, id) => {
              return <TopProject key={`top-project-${id}`} index={id} data={item.node} />
            })}
          </div>
          <p className={styles.allLists}><Link className={styles.allListsBullet} to="/project/">全てを見る</Link></p>
        </div>
        
        <div className={styles.information}>
          <h2 className={styles.headingTop}>お知らせ</h2>
          <div className={styles.informationInner}>
            { 
              informationData.map( (item, id) => {
                return <TopInformation key={`top-information-${id}`} index={id} data={item.node} />
              })
            }
          </div>
          <p className={styles.allLists}><Link className={styles.allListsBullet} to="/information/">全てを見る</Link></p>
        </div>
        
      </main>
    </Layout>
  )  
}
export default IndexPage

export const query = graphql`
  query TopPageQuery {
    feature: allMarkdownRemark(
      filter: {fields: {slug: {regex: "/article/"}}}
      sort: { fields: [frontmatter___order], order: ASC }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            order
            description
            mainImage
            slug
          }
        }
      }
    }
    staticArticle: allMarkdownRemark(
        filter: {fields: {slug: {regex: "/article/to-*/"}}}
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              mainImage
              slug
            }
          }
        }
    }
    project: allProjectsJson {
      edges {
        node {
          slug
          title
          description
          mainImage
          relatedResource {
            link
            title
          }
        }
      }
    }
    information: allMicrocmsInformation (
      limit: 5
    ) {
      edges {
        node {
          title
          link {
            blank
            fieldId
            href
          }
          publishedAt
        }
      }
    }
  }
`
