import React from "react"
import { Link } from "gatsby"
import * as styles from "../../../styles/page/top/project.module.scss"
import nl2br from "react-nl2br"
import specialCharacterReplace from "../../../utils/special-character-replace"

const TopProject = (props) => {
  const {slug, title} = props.data
  return (
    <Link key={`top-project-${props.index}`} to={`/project/${slug}/`} className={styles.projectCard}>
      <div className={styles.projectCardImage}>
        <img className={styles.Image} src={`/images/project/${slug}/og.png`} alt={title} />
      </div>
      <h3 className={styles.projectCardHeading} dangerouslySetInnerHTML={{ __html: nl2br(specialCharacterReplace(title)) }} />
    </Link>
  )
}

export default TopProject