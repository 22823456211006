import React from "react"
//import { Link } from "gatsby"
import * as styles from "../../../styles/page/top/information.module.scss"
import nl2br from "react-nl2br"
import { parseISO, format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import specialCharacterReplace from "../../../utils/special-character-replace"

export default function TopInformation(props) {
  const blank = props.data.link?.blank ? { target: '_blank', rel: 'openner noreferrer' } : {}
  return (  
    <>
      { 
        props.data.link?.href !== null ? 
            <a href={props.data.link.href} className={styles.informationCard} { ...blank }>
              <p className={styles.informationCardMeta}>{ format(parseISO(props.data.publishedAt), 'yyyy/MM/dd', {locale: ja}) }</p>
              <p className={styles.informationCardTitle}><span dangerouslySetInnerHTML={{__html: nl2br(specialCharacterReplace(props.data.title))}} /></p>
            </a>
            : 
            <div key={`top-information-${props.index}`} className={`${styles.informationCard} ${styles.noLink}`}>      
              <p className={styles.informationCardMeta}>{ format(parseISO(props.data.publishedAt), 'yyyy/MM/dd', {locale: ja}) }</p>
              <p className={styles.informationCardTitle}><span dangerouslySetInnerHTML={{__html: nl2br(specialCharacterReplace(props.data.title))}} /></p>
            </div>
      }
    </>
  )
}
