import React from "react"
import { Link } from "gatsby"
import * as styles from "../../../styles/page/top/feature.module.scss"
import specialCharacterReplace from "../../../utils/special-character-replace"

const TopFeature = (props) => {
  return (
    <Link key={`top-feature-${props.index}`} to={props.data.slug} className={styles.featureCard}>
      <div className={styles.featureCardImage}><img className={styles.Image} src={props.data.mainImage} alt={props.data.title} /></div>
      <div className={styles.featureCardContent}>
        <h3 className={styles.featureCardHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(props.data.title) }} />
        <p className={styles.featureCardMeta}>{props.data.date}</p>
        { (props.data.description) ?
          <p className={styles.featureCardSummary}><span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(props.data.description) }} /></p> : ''
        }
        <span className={styles.featureCardBullet}>詳しく見る</span>
      </div>
    </Link>
  )
}

export default TopFeature